






import LoginForm from '../components/LoginForm.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { SharedLayoutMain } from '../../../layouts';

@Component({
  name: 'LoginPage',
  components: {
    SharedLayoutMain,
    LoginForm,
  },
})
export default class LoginPage extends Vue {}
