
































import { User } from '@/models/User';
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue-demi';
import { routeNames } from '@/router/routeNames';
import { storage } from '@/main';
import { useLoader } from '@/composables/useLoader';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'LoginForm',
  setup() {
    const valid = ref(false);

    const showPassword = ref(false);
    const credentials = reactive({
      email: '',
      password: '',
    });

    const rules = {
      email: [(v: string): string | boolean => !!v || 'Email is required'],
      password: [(v: string): string | boolean => !!v || 'Password is required'],
    };

    const loginErrors = ref<string[]>([]);

    const user = ref<User | null>(null);

    const userIsSet = computed(() => user.value instanceof User);

    const loggedIn = computed(() => storage.auth.isAuthed);

    const { router, route } = useRouter();
    const { loading, withLoader } = useLoader();

    const goToRegister = () => {
      router.push({ name: 'register', query: route.value.query });
    };

    const login = async () =>
      withLoader(async () => {
        const loggedInUser = await User.login(credentials).catch((e) => {
          console.log(e);
        });

        if (loggedInUser) {
          user.value = loggedInUser;
          if (user.value) {
            storage.auth.login(user.value);
            if (user.value.data.type === 'practitioner') {
              router.push({ name: routeNames.practitioner.dashboard });
            } else {
              if (route.value.query.redirect) {
                router.push({ path: route.value.query.redirect as string });
                return;
              }
              router.push({ name: routeNames.patient.resources });
            }
          }
        }
      });

    const logout = async (): Promise<void> => {
      await User.logout();
    };

    const loginFailed = () => {
      loginErrors.value.push('Incorrect email or password.');
    };

    const clearErrors = () => {
      loginErrors.value = [];
    };

    onMounted(async () => {
      if (loggedIn.value) {
        user.value = await User.getCurrentUser();
      }
    });

    watch(credentials, () => clearErrors(), { deep: true });

    return {
      valid,
      loading,
      showPassword,
      credentials,
      rules,
      userIsSet,
      user,
      login,
      loggedIn,
      logout,
      loginErrors,
      goToRegister,
    };
  },
});
