
















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({ name: 'SharedLayoutMain' })
export default class SharedLayoutMain extends Vue {
  drawer = null;

  showSearch = true;

  isToolbarDetached = true;
}
